import { Injectable, NgZone } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Subject, BehaviorSubject, Observable, Subscriber, finalize } from 'rxjs';


class A11yFocusState {
  constructor(
    public previousElement: Element,
    public event: FocusEvent,
    public type: 'focus' | 'blur',
    public achieved: 'mouse' | 'keyboard'
  ) { }
}

export enum ScreenreaderPriority {
  Off = 'off', // supported by all major screen readers
  Polite = 'polite', // speaks, when user is not doing anything - screen reader will not interrupt - is supported by all major screen readers.
  Assertive = 'assertive' // screen reader usually interrupts the user
}


@Injectable({ providedIn: 'root' })
export class UtilA11yService {

  private readonly visibilitySubject = new Subject<boolean>();

  private readonly lastPossibleFocusChangingEventSubject = new BehaviorSubject<Event>(null);
  private readonly lastActiveElementSubject = new BehaviorSubject<Element>(null);

  get visibilityChange(): Observable<boolean> {
    return this.visibilitySubject.asObservable();
  }

  emitElementFocusStateChange(element: Element): Observable<A11yFocusState> {

    let outsideSubscriber: Subscriber<A11yFocusState>;

    const onFocusFunction = (ev: FocusEvent) => {
      this.lastActiveElementSubject.next(this.windowRef?.document?.activeElement);
      outsideSubscriber.next(new A11yFocusState(
        this.lastActiveElementSubject.value,
        ev,
        ev.type as ('focus' | 'blur'),
        this.lastPossibleFocusChangingEventSubject.value?.type === 'mousedown' ? 'mouse' : 'keyboard'
      ));
    };

    const onBlurFunction = (ev: FocusEvent) => {
      this.lastActiveElementSubject.next(this.windowRef?.document?.activeElement);
      outsideSubscriber.next(new A11yFocusState(
        this.lastActiveElementSubject.value,
        ev,
        ev.type as ('focus' | 'blur'),
        this.lastPossibleFocusChangingEventSubject.value?.type === 'mousedown' ? 'mouse' : 'keyboard'
      ));
    };

    const observable = new Observable((subscriber: Subscriber<A11yFocusState>) => {
      outsideSubscriber = subscriber;
      this.ngZone.runOutsideAngular(() => {
        element.addEventListener('focus', onFocusFunction);
        element.addEventListener('blur', onBlurFunction);
      });
    });

    return observable.pipe(finalize(() => {
      element.removeEventListener('focus', onFocusFunction);
      element.removeEventListener('blur', onBlurFunction);
    }));
  }

  constructor(private readonly ngZone: NgZone, private readonly windowRef: WindowRef) {
    this.ngZone.runOutsideAngular(() => {

      this.windowRef.document.addEventListener('visibilitychange', e => this.ngZone.run(() => this.visibilitySubject.next(!this.windowRef?.document?.hidden)));

      this.windowRef.document.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Tab') {
          this.lastActiveElementSubject.next(this.windowRef?.document?.activeElement);
          this.lastPossibleFocusChangingEventSubject.next(e);
        }
      });

      this.windowRef.document.addEventListener('mousedown', (e: MouseEvent) => {
        this.lastActiveElementSubject.next(this.windowRef?.document?.activeElement);
        this.lastPossibleFocusChangingEventSubject.next(e);
      });
    });
  }

  screenreaderSpeak(text: string, priority: 'assertive' | 'polite' = 'polite', lang?: 'de' | 'en') {

    if (window && document) {

      const el = document.createElement('div');
      const id = 'speak-' + Date.now();
      el.setAttribute('id', id);
      if (lang) {
        el.setAttribute('lang', lang);
      }
      el.setAttribute('aria-live', priority);
      el.classList.add('sr-only');

      document.body.appendChild(el);

      window.setTimeout(function () {
        document.getElementById(id).innerHTML = text;
      }, 100);

      window.setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
      }, 1000);

    }
  }
}
