import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { WindowRef } from '@spartacus/core';

import { Subscription } from 'rxjs/';
import { UtilA11yService } from '../../services/util-a11y.service';

/**
 * Returns a list of all focusable HTML elements inside the passed root
 * @param root Element to find docusable elements in
 */
export function retrieveFocusableElements(root: HTMLElement): NodeListOf<HTMLElement> {
  return root.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );
}

function isString(arg: any): arg is string { return typeof arg === 'string'; }

function isObject(arg: any): arg is { [attr: string]: any } { return typeof arg === 'object'; }


export interface KurzTooltipController {
  delegateFunction?: (element: HTMLElement) => HTMLElement;
  autoDelegate?: boolean;
  tooltip?: string;
  tooltipTemplate?: TemplateRef<any>;
}

export enum KurzTooltipPosition {
  top = 'top',
  topRight = 'top-right',
  right = 'right',
  bottomRight = 'bottom-right',
  bottom = 'bottom',
  bottomLeft = 'bottom-left',
  left = 'left',
  topLeft = 'top-left'
}

type KurzPreviousTooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after';


@Directive({
  selector: '[kurzTooltip]'
})
export class KurzTooltipDirective implements OnInit, AfterViewInit, OnDestroy {

  protected _tooltip: string = '';
  protected _tooltipTemplate: TemplateRef<any>;

  private static activeTooltip: KurzTooltipDirective;

  private static Id = 0;

  private uid = '__uniqueKurzTooltipDirective_Styles_ID_nr_' + KurzTooltipDirective.Id++;
  private _extraCSSRules: string = '';

  private static instanceCounter = 0;

  private static tooltipKeyframesStyleElement: HTMLStyleElement;

  static tooltipInlineCSS = '';

  static tooltipCSSClass = 'kurz-tooltip-class';

  static posOffsetX = 10;
  static posOffsetY = 10;

  // the order of position, of which the algorithm tries to test if the tooltip box fits
  static positionSequence = [
    KurzTooltipPosition.bottom,
    KurzTooltipPosition.bottomRight,
    KurzTooltipPosition.top,
    KurzTooltipPosition.topRight,
    KurzTooltipPosition.bottomLeft,
    KurzTooltipPosition.left,
    KurzTooltipPosition.topLeft,
    KurzTooltipPosition.right
  ];

  private get currentTemplateElement(): HTMLElement {
    return this.stack[this.stack.length - 1];
  }

  private focusableElement: HTMLElement;

  private _onlyContent = false;

  private get showing(): boolean {
    return !!this.stack.length;
  }

  // needed for the correct implementation of WAI-ARIA
  private readonly tooltipId = 'kurz-tooltip-id-' + ++KurzTooltipDirective.instanceCounter;
  private stack: HTMLElement[] = [];
  private _isLabel = false;
  private _beforeAriaText = '';
  private _disabled = false;
  private _showDelay = 0;
  private _hideDelay = 0;
  private _impolite = false;
  private _extraTooltipClasses: string[] = [];
  private _elementFocusStateChangeSubscription: Subscription;
  /**
   * elements, focused by pressing the tab, get an protection so that a mouseleave won't close it
   */
  private _mouseleaveProtection = false;

  private preferedPosition: KurzTooltipPosition[];

  @Input()
  set kurzTooltip(value: string) {
    this._tooltip = value;
  }

  get kurzTooltip(): string {
    return this._tooltip;
  }


  @Input()
  set kurzTooltipTemplate(value: TemplateRef<any>) {
    this._tooltipTemplate = value;
  }

  get kurzTooltipTemplate(): TemplateRef<any> {
    return this._tooltipTemplate;
  }


  @Input()
  set kurzTooltipIsLabel(value: boolean) {
    this._isLabel = value;
  }


  @Input()
  kurzTooltipController: KurzTooltipController = {
    autoDelegate: true
  };

  @Input()
  set kurzTooltipExtraRules(value: string) {
    this._extraCSSRules = value;
  }


  /* @Input check for backward compatibility */
  @Input()
  set kurzTooltipPosition(value: (KurzPreviousTooltipPosition | KurzTooltipPosition) | (KurzPreviousTooltipPosition | KurzTooltipPosition)[]) {
    if (Array.isArray(value)) {
      this.preferedPosition = (value as KurzTooltipPosition[]).map<KurzTooltipPosition>(str => KurzTooltipDirective.getKurzTooltipPosition(str));
    } else {
      this.preferedPosition = [KurzTooltipDirective.getKurzTooltipPosition(value)];
    }
  }

  private static getKurzTooltipPosition(value: (KurzPreviousTooltipPosition | KurzTooltipPosition)): KurzTooltipPosition {
    switch (value) {
      case 'left': return KurzTooltipPosition.left;
      case 'right': return KurzTooltipPosition.right;
      case 'above': return KurzTooltipPosition.top;
      case 'below': return KurzTooltipPosition.bottom;
      case 'before': return KurzTooltipPosition.left;
      case 'after': return KurzTooltipPosition.right;
      default: return value;
    }
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input()
  set kurzTooltipClass(value: string | string[] | Set<string> | { [key: string]: any }) {
    let classes: string[] = [];
    switch (true) {
      case (isString(value)): classes.push(value as string); break;
      case (Array.isArray(value)): classes = (value as string[]).map<string>(str => isString(str) ? str : ''); break;
      case (value instanceof Set): (value as Set<string>).forEach(str => classes.push(str)); break;
      case (isObject(value)): Object.keys(value).forEach(key => classes.push(value[key])); break;
    }

    this._extraTooltipClasses = classes;
  }

  private readonly viewContainerRef: ViewContainerRef;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly ngZone: NgZone,
    viewContainerRef: ViewContainerRef,
    private readonly kurzA11yService: UtilA11yService,
    private readonly windowRef: WindowRef
  ) {

    this.viewContainerRef = viewContainerRef;

    if (!KurzTooltipDirective.tooltipKeyframesStyleElement) {

      KurzTooltipDirective.tooltipKeyframesStyleElement = this.windowRef.document.createElement('style');

      const fadeInRule = this.getKeyframesRule('tooltipFadeIn', [
        { percent: 0, style: 'opacity: 0;' },
        { percent: 100, style: 'opacity: 1;' }
      ]);

      const fadeInNode = this.windowRef.document.createTextNode(fadeInRule);
      KurzTooltipDirective.tooltipKeyframesStyleElement.appendChild(fadeInNode);

      const fadeOutRule = this.getKeyframesRule('tooltipFadeOut', [
        { percent: 0, style: 'opacity: 1;' },
        { percent: 100, style: 'opacity: 0;' }
      ]);
      const fadeOutNode = this.windowRef.document.createTextNode(fadeOutRule);
      KurzTooltipDirective.tooltipKeyframesStyleElement.appendChild(fadeOutNode);
      this.windowRef.document.head.appendChild(KurzTooltipDirective.tooltipKeyframesStyleElement);
    }
  }


  private getKeyframesRule(name: string, keyframes: { percent: number; style: string }[]): string {
    const combinedKeyframes = keyframes.map<string>(f => f.percent + '% {' + f.style + ' }').join(' ');
    return `@keyframes ${name} {${combinedKeyframes}}`;
  }


  ngOnInit() {
  }


  ngAfterViewInit() {

    this.writeExtraRules();

    this.resetFocusableElement();

  }

  resetFocusableElement() {

    this._mouseleaveProtection = false;

    // remove event listener on old focusable element if there is one
    if (this.focusableElement) {
      this.focusableElement.removeEventListener('mouseenter', this.mouseenterFn);
      this.focusableElement.removeEventListener('mouseleave', this.mouseleaveFn);
    }

    if (this._elementFocusStateChangeSubscription) {
      this._elementFocusStateChangeSubscription.unsubscribe();
    }

    // find a new focusable element
    const el = (this.elementRef.nativeElement as HTMLElement);

    let autoDelegateResult: HTMLElement;
    let specifiedDelegateResult: HTMLElement;

    // all html custom elements must have a dash ('-') in the tag name to differentiate them from native html tags
    if (el.tagName.includes('-')) {

      // we need no auto delegation if element has a tab index of 0 or higher because it means
      // that the user of this directive made it accessable by pressing tab
      if (this.kurzTooltipController && this.kurzTooltipController.autoDelegate && el.tabIndex < 0) {
        // make sure that no result has an tabIndex of -1
        const result = Array.from(retrieveFocusableElements(el)).filter(elem => elem.tabIndex >= 0);
        if (result.length > 1) {
          console.warn('Auto delegation of the following element let to an inconclusive result.', el);
          autoDelegateResult = el;
        } else {
          autoDelegateResult = result[0];
        }
      }

      specifiedDelegateResult = this.kurzTooltipController.delegateFunction ? this.kurzTooltipController.delegateFunction(el) : null;
    }

    this.focusableElement = specifiedDelegateResult || autoDelegateResult || el;

    if (this.focusableElement) {

      this._elementFocusStateChangeSubscription = this.kurzA11yService.emitElementFocusStateChange(this.focusableElement).subscribe(state => {
        if (state.type === 'focus') {
          this._mouseleaveProtection = state.achieved === 'keyboard';
          this.show();
        } else { // on blur
          this._mouseleaveProtection = false;
          this.hide();
        }
      });

      this.ngZone.runOutsideAngular(() => {
        this.focusableElement.addEventListener('mouseenter', this.mouseenterFn);
        this.focusableElement.addEventListener('mouseleave', this.mouseleaveFn);
      });
    }
  }


  ngOnDestroy() {

    if (this._elementFocusStateChangeSubscription) {
      this._elementFocusStateChangeSubscription.unsubscribe();
    }

    if (this.focusableElement) {
      this.focusableElement.removeEventListener('mouseenter', this.mouseenterFn);
      this.focusableElement.removeEventListener('mouseleave', this.mouseleaveFn);
      this.hide();
    }

    this.removeExtraRules();

  }


  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  show(animationLength = 300, delay = this._showDelay) {

    // check if tooltip is not disabled or falsfied
    if (!this._disabled && this.getCurrentTooltip() && !this.showing && KurzTooltipDirective.activeTooltip !== this) {

      this.ngZone.runOutsideAngular(() => {
        this.windowRef.nativeWindow.addEventListener('scroll', this.scrollEventListenerIfShowing, true);
        this.windowRef.nativeWindow.addEventListener('keydown', this.keydownEventListenerIfShowing, true);
      });

      const localCurrentTemplateElement = this.getTemplateElement();
      localCurrentTemplateElement.style.opacity = '0';
      localCurrentTemplateElement.style.animationName = 'tooltipFadeIn';
      localCurrentTemplateElement.style.animationDuration = animationLength + 'ms';
      localCurrentTemplateElement.style.animationDelay = delay + 'ms';
      localCurrentTemplateElement.onanimationend = e => (e.target as HTMLElement).style.opacity = '1';

      if (this._isLabel) {
        this._beforeAriaText = this.focusableElement.getAttribute('aria-labelledby') || '';
        this.focusableElement.setAttribute('aria-labelledby', this.tooltipId);
      } else {
        this._beforeAriaText = this.focusableElement.getAttribute('aria-describedby') || '';
        this.focusableElement.setAttribute('aria-describedby', this.tooltipId);
      }


      // adding it to the dom so that its bounding rect can be calculated
      this.windowRef.document.body.appendChild(localCurrentTemplateElement);
      this.stack.push(localCurrentTemplateElement);

      const origin = this.focusableElement.getBoundingClientRect();
      const tmp = localCurrentTemplateElement.getBoundingClientRect();
      const windowBox = this.windowRef.document.body.getBoundingClientRect();

      const scrolledY = (windowBox.top ?? 0) * (-1);
      const scrolledX = (windowBox.left ?? 0) * (-1);

      const absoluteWindow = {
        left: (0 + scrolledX),
        right: (windowBox.width + scrolledX),
        top: (0 + scrolledY),
        bottom: (windowBox.height + scrolledY),
        width: windowBox.width,
        height: windowBox.height
      } as DOMRect;

      const absoluteOrigin = {
        left: (origin.left + scrolledX),
        right: (origin.right + scrolledX),
        top: (origin.top + scrolledY),
        bottom: (origin.bottom + scrolledY),
        width: origin.width,
        height: origin.height
      } as DOMRect;

      // dynamically calculate the position of the tooltip box
      const pos = this.calculatePosition(absoluteOrigin, tmp, absoluteWindow);
      localCurrentTemplateElement.style.left = pos.x + 'px';
      localCurrentTemplateElement.style.top = pos.y + 'px';
      localCurrentTemplateElement.classList.add('position-' + pos.pos);

      this.kurzA11yService.screenreaderSpeak(this.kurzTooltip);

      // hide other possible active tooltip
      KurzTooltipDirective.activeTooltip?.hide(0);
      // set this tooltip as the active one
      KurzTooltipDirective.activeTooltip = this;

    }
  }


  /* hide - in milliseconds */
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  hide(animationLength = 300, delay = this._hideDelay) {

    // a show() of another KurzTooltipDirective.activeTooltip (!= this) will call this hide()
    // and remove this protection
    this._mouseleaveProtection = false;

    this.windowRef.nativeWindow.removeEventListener('scroll', this.scrollEventListenerIfShowing, true);
    this.windowRef.nativeWindow.removeEventListener('keydown', this.keydownEventListenerIfShowing, true);

    const removeElementFromDOM = () => {
      if (this.currentTemplateElement) {
        if (this._isLabel) {
          if (this._beforeAriaText) {
            this.focusableElement.setAttribute('aria-labelledby', this._beforeAriaText);
          } else {
            this.focusableElement.removeAttribute('aria-labelledby');
          }
        } else if (this._beforeAriaText) {
          this.focusableElement.setAttribute('aria-describedby', this._beforeAriaText);
        } else {
          this.focusableElement.removeAttribute('aria-describedby');
        }

        this.removeElementsFromStack();

      }
    };

    if (this.showing) {

      KurzTooltipDirective.activeTooltip = null;

      if (animationLength > 0) {
        this.currentTemplateElement.style.animationName = 'tooltipFadeOut';
        this.currentTemplateElement.style.animationDuration = animationLength + 'ms';
        this.currentTemplateElement.style.animationDelay = delay + 'ms';
        this.currentTemplateElement.onanimationend = () => removeElementFromDOM();
        this.currentTemplateElement.onanimationcancel = () => removeElementFromDOM();

      } else {
        // if animationLength = 0 - the user wants to remove it directly
        // note: setTimeout(fn, 0) - removes the fn at the end of the script...
        // enough scripting time to override the static template and cause irregularities
        removeElementFromDOM();
      }
    }

  }


  private removeElementsFromStack() {
    this.stack.forEach(el => {
      el.parentElement.removeChild(el);
    });
    this.stack = [];
  }


  private readonly mouseenterFn = () => {
    this.show();
  };


  private readonly mouseleaveFn = () => {
    // a mouseleave only triggers the tooltip to hide if its targeted element is not protected
    if (!this._mouseleaveProtection) {
      this.hide();
    }
  };


  private readonly scrollEventListenerIfShowing = () => this.hide();

  private readonly keydownEventListenerIfShowing = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.code === 'Escape') {
      this.hide();
      e.stopPropagation();
      e.preventDefault();
    }
  };


  private getCurrentTooltip(): string {
    return this.kurzTooltipController.tooltip || this.kurzTooltip || '';
  }

  private getCurrentTooltipTemplate(): TemplateRef<any> {
    return this.kurzTooltipController.tooltipTemplate || this.kurzTooltipTemplate;
  }


  private getTemplateElement(): HTMLElement {
    const container = this.windowRef.document.createElement('div');

    // necessary for aria
    container.setAttribute('role', 'tooltip');
    container.setAttribute('id', this.tooltipId);
    container.setAttribute('aria-live', 'polite');

    // the zeta framework class
    container.classList.add(KurzTooltipDirective.tooltipCSSClass);
    // extra classes
    this._extraTooltipClasses.forEach(clazz => container.classList.add(clazz));
    // set KurzTooltipDirective global inline style
    if (KurzTooltipDirective.tooltipInlineCSS) {
      container.setAttribute('style', KurzTooltipDirective.tooltipInlineCSS);
    }

    const currentTooltip = this.getCurrentTooltip();
    const currentTooltipTemplate = this.getCurrentTooltipTemplate();

    if (!currentTooltipTemplate && isString(currentTooltip)) {
      const textNode = this.windowRef.document.createTextNode(currentTooltip);
      container.appendChild(textNode);
    }

    if (currentTooltipTemplate instanceof TemplateRef && isString(currentTooltip)) {
      this.ngZone.run(() => {
        const embeddedViewRef = this.viewContainerRef.createEmbeddedView(currentTooltipTemplate, { $implicit: currentTooltip });
        embeddedViewRef.rootNodes.forEach(node => {
          container.appendChild(node);
        });
        embeddedViewRef.markForCheck();
      });
    }
    return container;
  }


  private calculatePosition(
    origin: DOMRect,
    tmp: DOMRect,
    win: DOMRect
  ): { x: number; y: number; pos: KurzTooltipPosition } {

    let thisFits: { x: number; y: number; pos: KurzTooltipPosition } = null;

    const allPositions = [...KurzTooltipDirective.positionSequence];
    if (this.preferedPosition) {
      allPositions.splice(0, 0, ...this.preferedPosition);
    }

    // tests all positions in the sequence until one fits
    allPositions.some(pos => {
      thisFits = this.fits(pos, origin, tmp, win);
      return !!thisFits;
    });

    // if no position in the sequence fits and the first position is calculated again with the "must"-Flag
    return thisFits || this.fits(allPositions[0], origin, tmp, win, true);
  }


  /**
   * @param origin Rect of the focused element
   * @param tmp Rect of the template / tooltip box
   * @param win Rect of the document.body
   */
  private fits(
    position: KurzTooltipPosition,
    origin: DOMRect,
    tmp: DOMRect,
    win: DOMRect,
    must = false
  ): { x: number; y: number; pos: KurzTooltipPosition } {

    const pointInBox = (px: number, py: number, box: Partial<DOMRect>) => px >= box.left && px <= box.right && py >= box.top && py <= box.bottom;

    switch (position) {
      case KurzTooltipPosition.bottom: {
        const x1 = origin.left + (origin.width / 2) - (tmp.width / 2);
        const y1 = origin.bottom + KurzTooltipDirective.posOffsetY;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      case KurzTooltipPosition.top: {
        const x1 = origin.left + (origin.width / 2) - (tmp.width / 2);
        const y1 = origin.top - tmp.height - KurzTooltipDirective.posOffsetY;
        // const x2 = x1 + tmp.width;
        // const y2 = y1 + tmp.height;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      case KurzTooltipPosition.left: {
        const x1 = origin.left - tmp.width - KurzTooltipDirective.posOffsetX;
        const y1 = origin.top + (origin.height / 2) - (tmp.height / 2);
        // const x2 = x1 + tmp.width;
        // const y2 = y1 + tmp.height;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      case KurzTooltipPosition.right: {
        const x1 = origin.right + KurzTooltipDirective.posOffsetX;
        const y1 = origin.top + (origin.height / 2) - (tmp.height / 2);
        // const x2 = x1 + tmp.width;
        // const y2 = y1 + tmp.height;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      case KurzTooltipPosition.bottomLeft: {
        const x1 = origin.left - tmp.width - KurzTooltipDirective.posOffsetX;
        const y1 = origin.bottom + KurzTooltipDirective.posOffsetY;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      case KurzTooltipPosition.bottomRight: {
        const x1 = origin.right + KurzTooltipDirective.posOffsetX;
        const y1 = origin.bottom + KurzTooltipDirective.posOffsetY;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      case KurzTooltipPosition.topLeft: {
        const x1 = origin.left - tmp.width - KurzTooltipDirective.posOffsetX;
        const y1 = origin.top - tmp.height - KurzTooltipDirective.posOffsetY;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      case KurzTooltipPosition.topRight: {
        const x1 = origin.right + KurzTooltipDirective.posOffsetX;
        const y1 = origin.top - tmp.height - KurzTooltipDirective.posOffsetY;
        return (must || (pointInBox(x1, y1, win) && pointInBox(x1 + tmp.width, y1 + tmp.height, win)))
          ? { x: x1, y: y1, pos: position } : null;
      }
      default: return null;
    }
  }

  private writeExtraRules() {

    let styleEl = this.windowRef.document.head.querySelector('#' + this.uid);
    Array.from(styleEl?.childNodes || []).forEach(child => {
      styleEl?.removeChild(child);
    });

    if (this._extraCSSRules) {
      const rulesNode = this.windowRef.document.createTextNode(this._extraCSSRules);
      styleEl = styleEl || this.windowRef.document.createElement('style');
      styleEl.setAttribute('id', this.uid);
      styleEl.appendChild(rulesNode);
      this.windowRef.document.head.appendChild(styleEl);
    }

  }

  private removeExtraRules() {
    const styleEl = this.windowRef.document.head.querySelector('#' + this.uid);
    if (styleEl) {
      this.windowRef.document.head.removeChild(styleEl);
    }
  }

}
